<template>
    <hb-modal v-model="dialog" size="large" :title="taskTitle" show-help-link>
        <template v-slot:content>
            <hb-task
                :modal-view="true" 
                v-if="taskDataUpdated"
                class="task-details-modal"
                :type="taskDataUpdated.type"
                :hot-task="taskDataUpdated.hotTask"
                :icon="taskDataUpdated.icon | getIcon"
                :title="taskDataUpdated.title"
                :time="taskDataUpdated.time | formatDateServices"
                :assigned-to="taskDataUpdated.assignedTo"
                :created-by="taskDataUpdated.createdBy" 
                :created-by-time="taskDataUpdated.createdByTime | formatLocalDateTimeServices"
                :completed-by="taskDataUpdated.completedBy ? taskDataUpdated.completedBy : ''"
                :completed-by-time="taskDataUpdated.completedByTime | formatLocalDateTimeServices" 
                :updated-by="taskDataUpdated.updatedBy" 
                :updated-by-time="taskDataUpdated.updatedByTime | formatLocalDateTimeServices"
                :attachments="taskDataUpdated.attachments ? taskDataUpdated.attachments : ''" 
                :space="taskDataUpdated.space"
                :spaceType="taskDataUpdated.space ? taskDataUpdated.spaceType : ''"
                :expanded.sync="expanded"
                :contact-name="taskDataUpdated.contactName" 
                :contact-status="taskDataUpdated.contactStatus"
                :property="taskDataUpdated.property" 
            >
                <div class="mb-4">{{ taskDataUpdated.details }}</div>
                <hb-media-viewer
                    v-if="taskDataUpdated.mediaFiles?.length"
                    v-model="mediaViewer"
                    grid
                    grid-padding-off
                >
                    <template v-slot:slides>
                        <slide
                            v-for="(slide, i) in taskDataUpdated.mediaFiles"
                            :key="'slide_' + i"
                            :video="slide.video"
                            :video_src="slide.video_src"
                            :src="slide.src"
                        />
                    </template>
                </hb-media-viewer>

                <div v-if="taskDataUpdated.documents?.length">
                    <div class="hb-font-body-medium hb-text-night mt-n3">Attachments</div>
                    <div class="mt-3">
                        <hb-chip v-for="item in taskDataUpdated.documents" prepend-icon="mdi-paperclip" pointer>{{ item }}</hb-chip>
                    </div>
                </div>

            </hb-task>
        </template>
        <template v-slot:right-actions>
            <hb-btn :loading="isDismissing" :disabled="isDismissing" color="secondary" @click="updateTaskStatusEvent('dismiss')">Dismissed</hb-btn>
            <!-- <hb-btn color="secondary" @click="updateTaskStatusEvent('inprogress')">In-Progress</hb-btn> -->
            <hb-btn  :loading="isCompleting" :disabled="isCompleting" @click="updateTaskStatusEvent('completed')">Complete</hb-btn>
        </template>
    </hb-modal>
</template>

<script>
import api from '../../../assets/api.js';
import { notificationMixin } from '../../../mixins/notificationMixin.js';

export default {
    name: 'TaskDetails',
    mixins: [notificationMixin],
    data() {
        return {
            mediaViewer: false,
            expanded: false,
            isCompleting: false,
            isDismissing: false,
        }
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        taskDetails: {
            type: Object,
            default: {}
        },
        primaryRoleProperties: {
            type: Array,
            default: []
        },
        fetchTasks: {
            type: Function,
            default: () => {}
        }
    },
    computed: {
        dialog: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        },
        taskTitle() {
            let title = this.taskDetails?.TaskType ? this.taskDetails?.TaskType.label : 'Task Generic';
            title = title === 'Automated Communication' ? 'Operations' : title === 'Maintenance & Space Prep' ? 'maintenance' : title;
            return title;
        },
        taskDataUpdated() {
            if (!this.taskDetails) {
                return;
            }
            let item = this.taskDetails;
            let association = '';
            let assignees = '';
            let property = '';
            let attachments = [];
            let documents = [];
            if (item.Associations?.contact?.length) association = 'contact';
            else if(item.Associations?.space?.length) association = 'space';
            else if(item.Associations?.property?.length) association = 'property';
            else association = undefined;
            if (item.Assignees && (item.Assignees.Contacts?.length || item.Assignees.Roles?.length)) {
                let contacts = item.Assignees.Contacts.map(item => item && item.first + ' ' + item.last)
                let roles = item.Assignees.Roles.map(item => item && item.name);
                assignees = [...contacts, ...roles].join(', ');
            } else assignees = 'Everyone'

            if (association === 'property') {
                property = item.Associations?.property[0];
            }
            if (association === 'space' && this.primaryRoleProperties.find(itemInner => itemInner.id === item.Associations?.space[0].property_id)) {
                property = this.primaryRoleProperties.find(itemInner => itemInner.id === item.Associations?.space[0].property_id);
            }
            if (association === 'contact' 
                && item.Associations?.contact[0].Leases 
                && item.Associations?.contact[0].Leases.length 
                && item.Associations?.contact[0].Leases[0]?.Unit?.property_id
                && this.primaryRoleProperties.find(itemInner => itemInner.id === item.Associations?.contact[0].Leases[0]?.Unit?.property_id)) {
                property = this.primaryRoleProperties.find(itemInner => itemInner.id === item.Associations?.contact[0].Leases[0]?.Unit?.property_id);
            }

            if (property) {
                property = (property.number ? property.number + ' - ' : '') + (property.city ? property.city + ' - ' : '') + property.name;
            }
            if (item.Attachments?.length) {
                let images = item.Attachments.filter(item => item.mimetype.includes('image'));
                let videos = item.Attachments.filter(item => item.mimetype.includes('video'));
                let files = item.Attachments.filter(item => item.mimetype.includes('pdf'));
                if (images.length) attachments.push(images.length + (images.length > 1 ? ' images' : ' image'));
                if (videos.length) attachments.push(videos.length + (videos.length > 1 ? ' videos' : ' video'));
                if (files.length) {
                    attachments.push(files.length + (files.length > 1 ? ' attachments' : ' attachment'));
                    for (const element of files) {
                        documents.push(element.name)
                    }
                }
            }
            let taskObject = {
                id: item.id,
                type: association,
                hotTask: item.hot_task ? true : false,
                icon: item.TaskType.icon,
                title: item.title ? item.title : item.TaskType.name,
                title: item.TaskType.slug === 'other' && item.title ? item.title : item.TaskType.name,
                time: item.due_date,
                assignedTo: assignees,
                createdBy: item.created_by_details ? item.created_by_details.first + ' ' + item.created_by_details.last : '',
                createdByTime: item.created_at,
                updatedBy: item.modified_by_details ? item.modified_by_details.first + ' ' + item.modified_by_details.last : '', 
                updatedByTime: item.modified_at,
                attachments: attachments.length ? attachments.join(', ') : '',
                space: association === 'space' ? item.Associations?.space[0].number : association === 'contact' && property ? item.Associations?.contact[0].Leases[0]?.Unit.number  : '',
                expanded: false,
                contactName: association === 'contact' ? item.Associations.contact[0].first + ' ' + item.Associations.contact[0].last : '',
                contactStatus: item.Associations?.contact[0] ? item.Associations?.contact[0].status : '',
                property: property,
                details: item.details,
                status: item.status === 'pending' ? 'due date' : item.status === 'completed' ? 'complete' : '',
                mediaFiles: item.mediaFiles,
                documents,
                spaceType: 'storage'
            };
            if (item.status === 'completed') {
                taskObject = {
                    ...taskObject,
                    completedBy: item.modified_by_details ? item.modified_by_details.first + ' ' + item.modified_by_details.last : '',
                    completedByTime: item.modified_at
                }
            }
            return taskObject;
        }
    },
    methods: {
        async updateTaskStatusEvent(params) {
            try {
                if (params === 'dismiss') this.isDismissing = true;
                if (params === 'completed') this.isCompleting = true;
                // if (params === 'inprogress') this.isCompleting = true;
                await api.post(this, api.TASKS_CENTER + this.taskDetails.id + '/update-status', { status: params });
                this.dialog = false;
                this.fetchTasks(true);
            } catch (error) {
                this.showMessageNotification({type: 'error', description: error});
            } finally {
                this.isCompleting = false;
                this.isDismissing = false;
            }
        }
    },
}
</script>

<style scoped lang="scss">
.empty-image-box {
    width: 125px;
    height: 125px;
    border: 1.5px dashed #C4CDD5;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 4px;

    p {
        font-size: 14px;
        font-weight: 400;
        line-height: 23.8px;
        text-align: center;
        text-decoration-line: underline;
        text-decoration-style: solid;
        color: #00848E;
    }
}

.img-boxes {
    display: flex;
    // justify-content: space-between;
}

.image-box {
    width: 125px;
    height: 125px;
    overflow: hidden;
    position: relative;

    img,
    video {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &.cover-image:after {
        content: "Cover Image";
        position: absolute;
        left: 50%;
        bottom: 7px;
        color: white;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        width: 100%;
        transform: translateX(-30%);

    }

    .img-box-last {
        position: absolute;
        left: 0;
        top: 0;
        width: 125px;
        height: 125px;
        // background-color: rgba(0, 0, 0, 0.5);
        background: #363636B2;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        font-size: 24px;
        font-weight: 500;
        line-height: 32px;
    }
}

.top--2px {
    position: relative;
    top: -2px;
    padding: 0 4px;
    padding-right: 8px;
}

.image-upload input[type="file"] {
    display: none;
}
</style>

<style>
.task-details-modal .hb-task-background .hb-task-expanded-content-wrapper {
    padding-left: 20px !important;
}
</style>